import React, { useRef } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

// Config
import { colors } from "../../configs/_configSite";

// Components
import Label from "../Base/form-elements/Label";
import Textarea from "../Base/form-elements/Textarea";

const P = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.15rem;
`;

const TextBox = ({ name, label, required, error, handleChange, cssProp }) => {
  const labelRef = useRef(null);

  return (
    <P
      css={css`
        position: relative;
        ${cssProp}
      `}
    >
      <Label
        ref={labelRef}
        css={css`
          color: #ffffff;
          position: absolute;
          top: 0;
          pointer-events: none;
          transition: 0.2s;
          font-size: 1.15rem;
        `}
        htmlFor={`form-${name
          .toLowerCase()
          .split(" ")
          .join("-")}-${name}`}
      >
        {`${label}${required ? "*" : ""}`}
      </Label>

      {error}

      <Textarea
        css={css`
          height: 30px;
          background: transparent;
          border: none;
          border-bottom: 1px solid #ffffff;
          outline: none;
          color: #ffffff;
          resize: none;
          border-radius: 0;
          font-size: 1.15rem;
        `}
        required={required}
        id={`form-${name
          .toLowerCase()
          .split(" ")
          .join("-")}-${name}`}
        onFocus={() => {
          labelRef.current.style.transform = "translate(0px, -20px)";
          labelRef.current.style.fontSize = "75%";
          labelRef.current.style.color = colors.lightGray;
        }}
        onBlur={e => {
          if (e.currentTarget.value.length > 0) {
            // Do Nothing
          } else {
            labelRef.current.style.transform = "translate(0px, 0px)";
            labelRef.current.style.fontSize = "100%";
            labelRef.current.style.color = colors.white;
          }
        }}
        onChange={e => {
          e.currentTarget.style.height = "0px";
          e.currentTarget.style.height = `${11 +
            e.currentTarget.scrollHeight}px`;
          handleChange();
        }}
        name={name}
      />
    </P>
  );
};

TextBox.defaultProps = {
  name: "",
  label: "",
  required: false,
  error: "",
  handleChange: () => {},
  cssProp: ""
};

TextBox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  cssProp: PropTypes.string
};

export default TextBox;
