/* eslint-disable react/prop-types */
import React from "react";
import { css } from "@emotion/core";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default ({ cssProp }) => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "features/anana-vestige-firefly.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        loading="eager"
        css={css`
          ${cssProp}
        `}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    )}
  />
);
