import React from "react";
import styled from "@emotion/styled";

// Config
import { css } from "@emotion/core";
import Div100vh from "react-div-100vh";
import { colors, contact, mediaQueries } from "../../configs/_configSite";

// Components
import SocialIcons from "../Base/socials/SocialIcons";

// Images
import ContactForm from "../Forms/FormContact";
import TitleMain from "../Base/titles/TitleMain";
import TextAssistant30 from "../Base/text/TextAssistant30";
import { LinkTextExt } from "../Base/links/LinkText";
import ImgFeatureGoldLeaf from "../Images/Features/ImgFeatureGoldLeaf";
import LeavesLeftInner from "../LeavesBackground/LeavesLeftInner";
import LeavesLeftOuter from "../LeavesBackground/LeavesLeftOuter";
import LeavesRightInner from "../LeavesBackground/LeavesRightInner";
import LeavesRightOuter from "../LeavesBackground/LeavesRightOuter";
import FireFly from "../Animations/FireFly";

const ContainerMain = styled(Div100vh)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
`;

const BackgroundShadow = styled.div`
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    94.87% 340.63% at 50% 50%,
    rgba(11, 16, 18, 0.92) 0%,
    rgba(37, 75, 55, 0) 100%
  );
`;

const Grid = styled.section`
  position: relative;
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 1440px;
  padding: 0 30px;
  @media (max-width: ${mediaQueries.md}px) {
    grid-template-columns: 1fr;
  }
`;

const Content = styled.section`
  position: relative;
  transform: translateY(-20px);
`;

const WhiteLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.white};
`;

export default () => {
  return (
    <>
      <ContainerMain>
        <LeavesLeftOuter />
        <LeavesLeftInner />
        <LeavesRightInner />
        <LeavesRightOuter />
        <BackgroundShadow />
        <FireFly />
      </ContainerMain>
      <ContainerMain
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;

          @media (max-width: ${mediaQueries.md}px) {
            text-align: center;
          }
        `}
      >
        <Grid>
          <Content>
            <TitleMain
              css={css`
                display: flex;
                align-items: center;
                font-size: 2rem;
                color: ${colors.gold};

                @media (max-width: ${mediaQueries.md}px) {
                  justify-content: center;
                }

                @media (max-width: ${mediaQueries.xxs}px) {
                  font-size: 1.75rem;
                }

                > .gatsby-image-wrapper {
                  top: 0px;
                  width: 30px;
                  height: 30px;
                  left: 20px;
                  transform: rotate(225deg);
                  @media (max-width: ${mediaQueries.xxs}px) {
                    width: 25px;
                    height: 25px;
                  }
                }
              `}
            >
              Contact Us
              <ImgFeatureGoldLeaf />
            </TitleMain>
            <WhiteLine />
            <TextAssistant30
              css={css`
                color: ${colors.white};
                font-size: 4.6rem;
                font-weight: light;
                text-align: left;
                @media (max-width: ${mediaQueries.md}px) {
                  text-align: center;
                  font-size: 3rem;
                  margin-bottom: 0;
                }
                @media (max-width: ${mediaQueries.xs}px) {
                  font-size: 2rem;
                }
                @media (max-width: ${mediaQueries.xxs}px) {
                  font-size: 1.5rem;
                  margin-top: 1rem;
                }
              `}
            >
              San Diego, California
            </TextAssistant30>

            <LinkTextExt
              css={css`
                display: inline-block;
                text-decoration: none;
                font-size: 1.15rem;
              `}
              href={`mailto:${contact.emailAddress}`}
            >
              {contact.emailAddress}
            </LinkTextExt>
            <br />
            <br />
            <br />
            <SocialIcons
              colorHover={colors.gold}
              social="facebook"
              cssProp={`
              margin: 0 45px;
              @media (max-width: ${mediaQueries.md}px) {
                margin: 0 45px 0 0;
              }
            `}
            />
            <SocialIcons colorHover={colors.gold} social="instagram" />
          </Content>
          <div css={css``}>
            <ContactForm />
          </div>
        </Grid>
      </ContainerMain>
    </>
  );
};
