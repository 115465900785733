import React from "react";
import { PropTypes } from "prop-types";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import _configBase from "../../../configs/_configBase";
import { fonts, colors } from "../../../configs/_configSite";

// Images
import ImgLeafButtonLargeGreen from "../../Images/Features/ImgLeafButtonLargeGreen";
import ImgLeafButtonLargeBlack from "../../Images/Features/ImgLeafButtonLargeBlack";

// Styles
const Button = styled.button`
  ${_configBase}
  position: relative;
  display: inline-block;
  background: #00bea7;
  height: 60px;
  width: 100%;
  padding: 15px 30px;

  text-align: center;
  color: #ffffff;
  font-family: ${fonts.secondary};
  font-size: 1.25rem;
  font-weight: 600;
  transition: 0.2s;
  text-transform: uppercase;

  border: 1px solid #ffffff;
  outline: none;
  cursor: pointer;

  > div:nth-of-type(2) {
    transition: 0.2s;
  }

  > span {
    position: relative;
    transition: 0.2s;
  }

  &:hover {
    background: #000000;

    > span {
      color: ${colors.gold};
    }

    > div:nth-of-type(2) {
      opacity: 0;
    }
  }
`;

const ButtonLeafLarge = ({ children, type, onClick, cssProp }) => (
  <Button
    type={type}
    onClick={onClick}
    css={css`
      ${cssProp}
    `}
  >
    <ImgLeafButtonLargeBlack />
    <ImgLeafButtonLargeGreen />
    <span>{children}</span>
  </Button>
);

ButtonLeafLarge.defaultProps = {
  children: "Test",
  type: "button",
  onClick: () => {},
  cssProp: ""
};
ButtonLeafLarge.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  onClick: PropTypes.func,
  cssProp: PropTypes.string
};

export default ButtonLeafLarge;
