import styled from "@emotion/styled";
import _configBase from "../../../configs/_configBase";
import { fonts, colors, mediaQueries } from "../../../configs/_configSite";

export default styled.p`
  ${_configBase};
  color: ${colors.gold};
  font-family: ${fonts.tertiary};
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 30px;
  @media (max-width: ${mediaQueries.md}px) {
    font-size: 1rem;
  }
  @media (max-width: ${mediaQueries.xs}px) {
    font-size: 0.875rem;
  }
`;
