import React, { useState } from "react";
// import { PropTypes } from "prop-types";
// import { css } from "@emotion/core";
import styled from "@emotion/styled";
import TextInput from "../FormElements/TextInput";
import TextBox from "../FormElements/TextBox";
import ButtonLeafLarge from "../Base/buttons/ButtonLeafLarge";

const Form = styled.form`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;
`;
const Text = styled.p`
  font-size: 1.15rem;
  font-family: Blinker;
  color: #fff;
`;

const ContactForm = () => {
  const [status, setStatus] = useState();
  const submitForm = ev => {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
      } else {
        setStatus("ERROR");
      }
    };
    xhr.send(data);
  };
  return (
    <Form
      onSubmit={submitForm}
      action="https://usebasin.com/f/ff0e45102b79.json"
      method="POST"
    >
      <TextInput name="Name" label="Name" required />
      <TextInput name="Email" label="Email" required />
      <TextBox
        name="Message"
        label="Enquiry Details..."
        required
        cssProp="grid-column: span 2; margin-bottom: 60px;"
      />
      {status === "SUCCESS" ? (
        <Text>Thanks!</Text>
      ) : (
        <ButtonLeafLarge type="submit" cssProp="grid-column: span 2;">
          Send
        </ButtonLeafLarge>
      )}
      {status === "ERROR" && <p>Ooops! There was an error.</p>}
    </Form>
  );
};

// ContactForm.defaultProps = {
// prop: "test"
// };
// ContactForm.propTypes = {
// prop: PropTypes.string
// };

export default ContactForm;
