import { Link } from "gatsby";
import styled from "@emotion/styled";
import _configBase from "../../../configs/_configBase";
import { colors, fonts } from "../../../configs/_configSite";

const styles = `
  ${_configBase};
  color: ${colors.white};
  font-family: ${fonts.secondary};
  font-size: 1rem;
  transition: 0.2s;
  &:hover {
    color: ${colors.gold};
  }
`;

export const LinkTextInt = styled(Link)`
  ${styles}
`;

export const LinkTextExt = styled.a`
  ${styles}
`;
