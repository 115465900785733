import React from "react";

// Components
import Layout from "../components/Layouts/Layout";
import SEO from "../components/Utilities/SEO";
import Contact from "../components/Sections/SectionContact";

const ContactPage = () => {
  return (
    <Layout>
      <SEO
        title="Get In Touch | Anana Vestige"
        description="Come discover a lifestyle to KNOW how much value you possess, to BELIEVE in the truth of who you are, and to FULFILL the great task you have been given."
      />
      <Contact />
    </Layout>
  );
};

export default ContactPage;
