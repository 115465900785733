import React from "react";
import { keyframes, css } from "@emotion/core";
import styled from "@emotion/styled";
import ImgFeatureFirefly from "../Images/Features/ImgFeatureFirefly";

const Pulse = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.2;
  }
`;

const FlightLeftRight1 = keyframes`
  0% {
    left: 20%;
  }
  10% {
    left: 30%;
  }
  20% {
    left: 25%;
  }
  30% {
    left: 40%;
  }
  40% {
    left: 30%;
  }
  50% {
    left: 33%;
  }
  60% {
    left: 27%;
  }
  70% {
    left: 22%;
  }
  80% {
    left: 30%;
  }
  90% {
    left: 26%;
  }
  100% {
    left: 35%;
  }
`;

const FlightLeftRight2 = keyframes`
  0% {
    right: 30%;
  }
  10% {
    right: 20%;
  }
  20% {
    right: 25%;
  }
  30% {
    right: 35%;
  }
  40% {
    right: 25%;
  }
  50% {
    right: 30%;
  }
  60% {
    right: 40%;
  }
  70% {
    right: 35%;
  }
  80% {
    right: 40%;
  }
  90% {
    right: 45%;
  }
  100% {
    right: 30%;
  }
`;

const FlightUpDown1 = keyframes`
  0% {
    top: 65%;
  }
  5% {
    top: 75%;
  }
  15% {
    top: 65%;
  }
  25% {
    top: 55%;
  }
  35% {
    top: 60%;
  }
  45% {
    top: 70%;
  }
  55% {
    top: 75%;
  }
  65% {
    top: 70%;
  }
  75% {
    top: 85%;
  }
  85% {
    top: 85%;
  }
  95% {
    top: 75%;
  }
  100% {
    top: 65%;
  }
`;

const FlightUpDown2 = keyframes`
  0% {
    top: 80%;
  }
  5% {
    top: 70%;
  }
  15% {
    top: 75%;
  }
  25% {
    top: 68%;
  }
  35% {
    top: 73%;
  }
  45% {
    top: 80%;
  }
  55% {
    top: 74%;
  }
  65% {
    top: 69%;
  }
  75% {
    top: 65%;
  }
  85% {
    top: 70%;
  }
  95% {
    top: 73%;
  }
  100% {
    top: 80%;
  }
`;

const FlightUpDown3 = keyframes`
  0% {
    bottom: 65%;
  }
  5% {
    bottom: 75%;
  }
  15% {
    bottom: 65%;
  }
  25% {
    bottom: 55%;
  }
  35% {
    bottom: 60%;
  }
  45% {
    bottom: 70%;
  }
  55% {
    bottom: 75%;
  }
  65% {
    bottom: 70%;
  }
  75% {
    bottom: 85%;
  }
  85% {
    bottom: 85%;
  }
  95% {
    bottom: 75%;
  }
  100% {
    bottom: 65%;
  }
`;

const FireFly = styled.div`
  z-index: 5;
  position: absolute;
  border-radius: 50%;
  width: 100px;
  height: 100px;
`;

export default () => {
  return (
    <>
      <FireFly
        css={css`
          width: 150px;
          height: 150px;
          animation: ${FlightLeftRight1} 24s infinite ease-in-out,
            ${FlightUpDown1} 15s infinite ease-in-out, ${Pulse} 1s infinite;
        `}
      >
        <ImgFeatureFirefly />
      </FireFly>
      <FireFly
        css={css`
          width: 75px;
          height: 75px;
          animation: ${FlightLeftRight2} 20s infinite ease-in-out,
            ${FlightUpDown2} 12s infinite ease-in-out, ${Pulse} 1.5s infinite;
        `}
      >
        <ImgFeatureFirefly />
      </FireFly>
      <FireFly
        css={css`
          width: 50px;
          height: 50px;
          animation: ${FlightLeftRight2} 29s infinite ease-in-out,
            ${FlightUpDown3} 35s infinite ease-in-out, ${Pulse} 2s infinite;
        `}
      >
        <ImgFeatureFirefly />
      </FireFly>
    </>
  );
};
